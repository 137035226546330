const chevronDownSVG = '<svg fill="currentColor" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>';
const chevronUpSVG = '<svg fill="currentColor" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>';

const initSummaryChevrons = (reset = null) => {
    const details = document.getElementsByTagName('details');

    for (const detail of details) {
        const summary = detail.getElementsByTagName('summary')[0];

        if (reset) {
            summary.removeEventListener('click', clickHandler(summary, detail));
        }
        summary.addEventListener('click', clickHandler(summary, detail));
    }
}

const clickHandler = (summary, detail) => {
    return () => {
        const chevron = summary.querySelector('[data-chevron]');

        if (detail.hasAttribute('open')) {
            chevron.innerHTML = chevronDownSVG;
        } else {
            chevron.innerHTML = chevronUpSVG;
        }

        resetChevrons(detail)
    }
}

const resetChevrons = (detail) => {
    const otherDetails = document.getElementsByTagName('details');

    for (const otherDetail of otherDetails) {
        if (detail === otherDetail) {
            continue;
        }
        otherDetail.removeAttribute('open');
        const summary = otherDetail.getElementsByTagName('summary')[0];
        const chevron = summary.querySelector('[data-chevron]');
        chevron.innerHTML = chevronDownSVG;
    }
}

initSummaryChevrons();

window.resetChevrons = initSummaryChevrons;
