import Glide from '@glidejs/glide';
import { setClickHandlers } from "./large-slider-click-handlers.js";
import { setEventHandlers } from "./large-slider-event-handlers.js";
import { sliderOptions } from "./options.js";

const sliders = Array.from(document.querySelectorAll('[data-slider-type="large_slider"]'))

for (const slider of sliders) {
    const glide = new Glide(slider, sliderOptions())

    setEventHandlers(glide, slider)
    setClickHandlers(slider, glide)

    glide.mount();
}

