import gsap from './animations/gsap';

const infoboxes = Array.from(document.querySelectorAll('[data-infobox]'));

for (const infobox of infoboxes) {
    const headlines = Array.from(infobox.querySelectorAll('[data-headline]'));

    for (const headline of headlines) {
        headline.addEventListener('click', async (e) => {
            for (const element of headlines) {
                element.disabled = true;
            }

            const index = e.target.dataset.headline;
            const description = infobox.querySelector(`[data-description='${index}']`);
            const image = infobox.querySelector(`[data-image='${index}']`);

            if (headline.classList.contains('active')) {
                for (const element of headlines) {
                    element.disabled = false;
                }

                return;
            } else {
                const activeElement = infobox.querySelector('.active');
                const activeIndex = activeElement.dataset.headline;
                const activeDescription = infobox.querySelector(`[data-description='${activeIndex}']`);
                const activeImage = infobox.querySelector(`[data-image='${activeIndex}']`);
                closeAnimation(activeDescription);
                hideAnimation(activeImage);
                activeElement.classList.remove('active');
                headline.classList.add('active');
            }

            openAnimation(description);
            await showAnimation(image)

            for (const element of headlines) {
                element.disabled = false;
            }
        });
    }
}

const openAnimation = (el) => {
    return gsap.to(
        el,
        {
            duration: 1,
            maxHeight: '100%'
        }
    ).then();
}

const closeAnimation = (el) => {
    return gsap.to(
        el,
        {
            duration: 0.3,
            maxHeight: '0px'
        }
    ).then();
}

const showAnimation = (el) => {
    return gsap.to(
        el,
        {
            duration: 1,
            autoAlpha: 1
        }
    ).then();
}

const hideAnimation = (el) => {
    return gsap.to(
        el,
        {
            duration: 0.3,
            autoAlpha: 0
        }
    ).then();
}