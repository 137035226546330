export const initFilterRangeInput = () => {
    const rangeElements = document.getElementsByClassName('range-input')
    const generateBackground = (rangeElement, options) => {
        //Set bg gradient
        const percentage =  (rangeElement.value - options.min) / (options.max - options.min) * 100
        return 'background: linear-gradient(to right, #a1141c, #e2001a ' + percentage + '%, #d3edff ' + percentage + '%, #dee1e2 100%)'
    }

    for (const rangeElement of rangeElements) {
        const options = {
            min: rangeElement.dataset.min,
            max: rangeElement.dataset.max,
            initValue: rangeElement.dataset.init
        }

        const valueElements = document.querySelectorAll(`[data-value="${rangeElement.dataset.range}"]`)

        //Init state
        rangeElement.setAttribute('min', options.min)
        rangeElement.setAttribute('max', options.max)
        rangeElement.value = options.initValue
        valueElements.forEach(valueElement => {
            valueElement.innerHTML = rangeElement.value
        });
        rangeElement.style = generateBackground(rangeElement, options);

        //Default input event to track mouse slide
        rangeElement.addEventListener('input', () => {
            valueElements.forEach(valueElement => {
                valueElement.innerHTML = rangeElement.value
            });
            rangeElement.style = generateBackground(rangeElement, options)
        });

        //Change event to set bg after js change
        rangeElement.addEventListener('change', () => {
            valueElements.forEach(valueElement => {
                valueElement.innerHTML = rangeElement.value
            });
            rangeElement.style = generateBackground(rangeElement, options)
        });
    }
}