export const inputChangeListener = () => {
    // Get every finder input
    document.querySelectorAll('[data-finder-input]').forEach(finderInput => {

        // Get every input with the same name
        const inputs = document.querySelectorAll(`[name="${finderInput.name}"]`);

        inputs.forEach(input => {
            input.addEventListener('input', (event) => {
                inputs.forEach(inputElement => {
                    if (input.type === "checkbox" || input.type === "radio") {
                        if (input.value === inputElement.value) {
                            inputElement.checked = input.checked;

                            const activeFilterElement = document.querySelector(`[data-active-filter-item][data-input-name="${input.name}"][data-input-value="${input.value}"]`).parentElement

                            if (input.checked) {
                                activeFilterElement.classList.remove('hidden');
                            } else {
                                activeFilterElement.classList.add('hidden');
                            }
                        }
                    } else {
                        inputElement.value = event.target.value;

                        const activeFilterElement = document.querySelector(`[data-active-filter-item][data-input-name="${input.name}"][data-input-id="${input.id}"]`)

                        const minValue = activeFilterElement.dataset.inputMinVal;

                        activeFilterElement.parentElement.querySelector('[data-input-value]').innerHTML = event.target.value;

                        if (event.target.value === minValue) {
                            activeFilterElement.parentElement.classList.add('hidden');
                        } else {
                            activeFilterElement.parentElement.classList.remove('hidden');
                        }

                    }
                    // JS value update doesn't trigger the change event, but it's needed for the range inputs
                    inputElement.dispatchEvent(new Event('change', { bubbles: true }));
                })
            })
        })

    })
}