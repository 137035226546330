const filterOptions = document.querySelectorAll('[data-active-filter-item]');

const filterResetButton = document.querySelector('[data-reset-filter]');

if (filterOptions && (filterOptions.length > 0)) {
    filterOptions.forEach(filterOption => {
        filterOption.addEventListener('click', (e) => {
            filterOption.parentElement.classList.add('hidden');

            if (filterOption.dataset.activeFilterCheckbox) {
                const mainFilter = document.querySelector(`[data-finder-input][name="${filterOption.dataset.inputName}"][value="${filterOption.dataset.inputValue}"]`)

                mainFilter.checked = false;
                // Trigger htmx to fire request
                mainFilter.dispatchEvent(new Event('input', { bubbles: true }));
            } else if (filterOption.dataset.activeFilterNumber) {
                const mainFilter = document.querySelector(`[data-finder-input][name="${filterOption.dataset.inputName}"][id="${filterOption.dataset.inputId}"]`)

                mainFilter.value = filterOption.dataset.inputMinVal;
                // Trigger htmx to fire request
                mainFilter.dispatchEvent(new Event('input', { bubbles: true }));
            }
        })
    });

    filterResetButton.addEventListener('click', (e) => {
        let lastFilterOption = null;
        filterOptions.forEach(filterOption => {
            filterOption.parentElement.classList.add('hidden');

            if (filterOption.dataset.activeFilterCheckbox) {
                document.querySelectorAll(`[name="${filterOption.dataset.inputName}"][value="${filterOption.dataset.inputValue}"]`).forEach(input => {
                    input.checked = false;
                })
            } else if (filterOption.dataset.activeFilterNumber) {
                document.querySelectorAll(`[name="${filterOption.dataset.inputName}"][id="${filterOption.dataset.inputId}"]`).forEach(input => {
                    input.value = filterOption.dataset.inputMinVal;
                    input.dispatchEvent(new Event('change', { bubbles: true }));
                })
            }

            lastFilterOption = filterOption;
        });

        lastFilterOption.click();
    })
}