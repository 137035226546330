import Glide from '@glidejs/glide';

const sliders = document.querySelectorAll('[data-slider]')

for (const slider of sliders) {
    const slidesPerView = slider.dataset.slidesPerView;
    const slidesPerViewSm = slider.dataset.slidesPerViewSm;
    const slidesPerViewMd = slider.dataset.slidesPerViewMd;
    const slidesPerViewLg = slider.dataset.slidesPerViewLg;
    const autoplay = slider.dataset.autoplay;
    const gap = slider.dataset.gap;
    const treshold = slider.dataset.treshold === 'false' ? false : slider.dataset.treshold;

    const glide = new Glide(slider, {
        type: 'slider',
        bound: true,
        perView: slidesPerView,
        startAt: 0,
        autoplay: autoplay * 1000,
        hoverpause: true,
        keyboard: true,
        dragThreshold: treshold,
        swipeThreshold: treshold,
        animationDuration: 1000,
        gap: gap,
        perTouch: false,
        peek: {
            before: 0,
            after: 0,
        },
        breakpoints: {
            767: {
                perView: slidesPerViewSm,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
            1279: {
                perView: slidesPerViewMd,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
            1535: {
                perView: slidesPerViewLg,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
        },
        classes: {
            direction: {
                ltr: 'glide--ltr',
                rtl: 'glide--rtl',
            },
            slider: 'glide--slider',
            carousel: 'glide--carousel',
            swipeable: 'glide--swipeable',
            dragging: 'glide--dragging',
            cloneSlide: 'glide__slide--clone',
            activeNav: 'glide__bullet--active',
            activeSlide: 'glide__slide--active',
            disabledArrow: 'glide__arrow--disabled',
        }
    })
    glide.mount()
}

