const chevronDownSVG = '<svg fill="currentColor" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>';
const chevronUpSVG = '<svg fill="currentColor" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>';

const langMenus = document.querySelectorAll('[data-lang-menu]');

// Click handler for the lang pickers
langMenus.forEach((menu) => {
    const langButton = menu.querySelector('[data-lang-button]');

    langButton.addEventListener('click', () => {
        if (langButton.classList.contains('open')) {
            closeLangPicker(menu);
        } else {
            // Close every lang picker
            langMenus.forEach((closeable) => {
                closeLangPicker(closeable);
            });
            // Open the clicked lang picker
            openLangPicker(menu);
        }
    });
})

// Close the pickers on scroll
document.addEventListener('scroll', () => {
    langMenus.forEach((menu) => {
        closeLangPicker(menu)
    });
})

const openLangPicker = (menu) => {
    const langButton = menu.querySelector('[data-lang-button]');
    const langChevron = langButton.querySelector('[data-lang-chevron]');
    const langDropdown = menu.querySelector('.lang-dropdown-content');

    langButton.classList.add('open');
    langDropdown.classList.remove('hidden');
    langChevron.innerHTML = chevronUpSVG;
}
const closeLangPicker = (menu) => {
    const langButton = menu.querySelector('[data-lang-button]');
    const langChevron = langButton.querySelector('[data-lang-chevron]');
    const langDropdown = menu.querySelector('.lang-dropdown-content');

    langButton.classList.remove('open');
    langChevron.innerHTML = chevronDownSVG;
    langDropdown.classList.add('hidden');
}