import gsap from '../animations/gsap'

export const imgAnimation = (prevEl, nextEl) => {
    nextEl.classList.add('z-[100]')

    gsap.to(nextEl, { autoAlpha: 1 })

    gsap.to(
        prevEl,
        {
            autoAlpha: 0,
            duration: 0.2,
        }
    ).then(() => {
        prevEl.classList.remove('z-[101]')
        nextEl.classList.add('z-[101]')
        nextEl.classList.remove('z-[100]')
    })
}
