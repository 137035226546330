const scrollIndicators = {
    left: document.querySelector('.scroll-indicator-left'),
    right: document.querySelector('.scroll-indicator-right')
}

const scrollableElements = {
    left: document.querySelector('.left-column'),
    right: document.querySelector('.right-column')
}

scrollIndicators.left.style.width = `${scrollableElements.left.offsetWidth}px`
scrollIndicators.right.style.width = `${scrollableElements.right.offsetWidth}px`

function animateScrollIndicator(el, key) {
    let indicator = scrollIndicators[key]
    let tolerance = 70

    setTimeout(() => {
        // if the scroll position is at the bottom of the element
        if (el.scrollTop + el.clientHeight >= el.scrollHeight - tolerance) {
            indicator.classList.add('flyOut')
            indicator.classList.add('hide')
            indicator.classList.remove('fadeIn')
        } else {
            indicator.classList.remove('flyOut')
            indicator.classList.remove('hide')
            indicator.classList.add('fadeIn')
        }
    }, 400)
}

// updates scroll indicators based on scroll position
Object.keys(scrollableElements).forEach((key) => {
    let el = scrollableElements[key]

    el.addEventListener('scroll', () => {
        animateScrollIndicator(el, key)
    })
})

// updates scroll indicators on menu clicks
document.addEventListener('menu-click', () => {
    Object.keys(scrollableElements).forEach((key) => {
        // scrollIndicators[key].classList.add('hide')
        // scrollIndicators[key].classList.remove('fadeIn')
        // scrollIndicators[key].classList.remove('flyOut')
        animateScrollIndicator(scrollableElements[key], key)
    })
})
