document.querySelectorAll("[data-finder-input]").forEach(input => {
    input.addEventListener("change", onInputChange);
});

function onInputChange(event) {
    const inputs = document.querySelectorAll("[data-finder-input]");

    const params = serializeFormData(inputs);

    updateUrlParams(params);
}

function serializeFormData(inputs) {
    let params = {};
    inputs.forEach(input => {
        const name = input.name.endsWith("[]") ? input.name.slice(0, -2) : input.name;

        if ((input.type === "checkbox" || input.type === "radio")) {
            if (input.checked) {
                // Array query param handling
                if (input.name.endsWith("[]")) {
                    if (!params[name]) {
                        params[name] = [];
                    } else if (!Array.isArray(params[name])) {
                        params[name] = [params[name]];
                    }
                    params[name].push(input.value);
                }
                // Standalone checkbox / radio button handling
                else {
                    params[name] = input.value;
                }
            }
        } else if (input.value) {
            params[name] = input.value;
        }
    });
    return params;
}

function updateUrlParams(params) {
    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;

    const paramStr = new URLSearchParams();

    for (const key in params) {
        if (Array.isArray(params[key])) {
            params[key].forEach(value => paramStr.append(`${key}[]`, value));
        } else {
            paramStr.append(key, params[key]);
        }
    }
    if (paramStr.toString()) {
        newUrl += "?" + paramStr.toString();
    }
    history.replaceState(null, "", newUrl);
}
