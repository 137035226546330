import PhotoSwipeLightbox from 'photoswipe/lightbox';

const galleries = Array.from(document.querySelectorAll('[data-photoswipe-gallery]'));

for (const gallery of galleries) {
    const lightbox = new PhotoSwipeLightbox({
        gallery: gallery,
        children: gallery.querySelectorAll('[data-gallery-img]'),
        pswpModule: () => import('photoswipe')
    });
    lightbox.init();
}
