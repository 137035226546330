import gsap from './gsap'

const containers = document.querySelectorAll('[data-brick="vertical_links"]')
const page = document.querySelector('#page')

containers.forEach((container, i) => {
    const links = container.querySelectorAll('li')

    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            pinnedContainer: page,
            start: 'bottom bottom',
        },
    })

    links.forEach((link, i) => {
        timeline.fromTo(
            link,
            {
                xPercent: -200,
            },
            {
                xPercent: 0,
                duration: 0.25,
            }
        )

        const icon = link.querySelector('svg')

        const wiggle = gsap
            .timeline({ repeat: 0, paused: true })
            .to(icon, { rotate: -15, duration: 0.1, ease: 'power1.out' })
            .to(icon, { rotate: 15, duration: 0.1, ease: 'power1.out' })
            .to(icon, { rotate: -15, duration: 0.1, ease: 'power1.out' })
            .to(icon, { rotate: 15, duration: 0.1, ease: 'power1.out' })
            .to(icon, { rotate: 0, duration: 0.1, ease: 'power1.out' })

        link.addEventListener('mouseenter', () => wiggle.restart())
    })
})


const buttons = Array.from(document.querySelectorAll('[data-vertical-link]'))

for (const element of buttons) {
    element.addEventListener('mouseenter', () => {
        mouseEnterLink(element.querySelector('[data-link-text]'))
        mouseEnterText(element)
    });
    element.addEventListener('mouseleave', () => {
        mouseLeaveLink(element.querySelector('[data-link-text]'))
        mouseLeaveText(element)
    });
}

const mouseEnterLink = (element) => {
    gsap.to(
        element,
        {
            duration: 0,
            autoAlpha: 0,
            x: '3rem',
        }
    )
    gsap.to(
        element,
        {
            duration: 0.5,
            autoAlpha: 1,
            x: 0,
        }
    )
}

const mouseLeaveLink = (element) => {
    gsap.to(
        element,
        {
            duration: 0.5,
            autoAlpha: 0,
            x: '100%',
        }
    )
}
const mouseEnterText = (el) => {
    const text = el.querySelector('[data-button-text]')
    const teaser = el.querySelector('[data-button-teaser]')

    if (teaser.innerHTML === "") {
        return;
    }

    gsap.to(
        teaser,
        {
            duration: 0.3,
            autoAlpha: 0,
        }
    )
    gsap.to(
        text,
        {
            duration: 0.3,
            y: '0.75rem'
        }
    )
}

const mouseLeaveText = (el) => {
    const text = el.querySelector('[data-button-text]')
    const teaser = el.querySelector('[data-button-teaser]')

    if (teaser.innerHTML === "") {
        return;
    }

    gsap.to(
        text,
        {
            duration: 0.3,
            y: 0
        }
    )
    gsap.to(
        teaser,
        {
            duration: 0.3,
            autoAlpha: 1,
        }
    )
}