const containers = Array.from(document.querySelectorAll('[data-more-container]'));

for (const container of containers) {
    const elements = Array.from(container.querySelectorAll('[data-more-element]'));
    const button = container.querySelector('[data-more-button]');

    if (button) {
        button.addEventListener('click', (e) => {
            e.preventDefault();

            button.parentElement.classList.add('hidden');

            for (const element of elements) {
                element.classList.remove('hidden');
            }
        });
    }
}