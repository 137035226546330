//Default params are for large-slider
export const sliderOptions = (perView = 4, perViewBreakpoint1 = 1.1, perViewBreakpoint2 = 2, perViewBreakpoint3 = 3, gap = 50, type = 'slider') => {
    return {
        type: type,
        bound: true,
        perView: perView,
        startAt: 0,
        autoplay: false,
        hoverpause: true,
        keyboard: true,
        dragThreshold: 50,
        animationDuration: 1000,
        gap: gap,
        perTouch: false,
        peek: {
            before: 0,
            after: 0,
        },
        breakpoints: {
            648: {
                perView: perViewBreakpoint1,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
            1024: {
                perView: perViewBreakpoint2,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
            1280: {
                perView: perViewBreakpoint3,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
        },
        classes: {
            direction: {
                ltr: 'glide--ltr',
                rtl: 'glide--rtl',
            },
            slider: 'glide--slider',
            carousel: 'glide--carousel',
            swipeable: 'glide--swipeable',
            dragging: 'glide--dragging',
            cloneSlide: 'glide__slide--clone',
            activeNav: 'glide__bullet--active',
            activeSlide: 'glide__slide--active',
            disabledArrow: 'glide__arrow--disabled',
        }
    }
}
