import gsap from './gsap'

const fadeIns = gsap.utils.toArray('.fade-in')
const page = document.querySelector('#page')
fadeIns.forEach((el, i) => {
    gsap.fromTo(
        el,
        { autoAlpha: 0, y: 150 },
        {
            duration: 0.5,
            autoAlpha: 1,
            y: 0,
            scrollTrigger: {
                pinnedContainer: page,
                trigger: el,
                start: 'top bottom-=-50px',
                toggleActions: 'play none none none',
                once: true,
            },
        }
    )
})
