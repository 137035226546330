import gsap from '../animations/gsap'

export const openAnimation = (el) => {
    return gsap.fromTo(el, {
        autoAlpha: 0,
        y: '3rem'
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.1
    }).then();
}

export const closeAnimation = (el) => {
    return gsap.to(el, {
        y: '3rem',
        autoAlpha: 0,
        duration: 0.1
    }).then();
}

export const mobileOpenAnimation = (el) => {
    return gsap.to(el, {
        width: '100%',
        borderRadius: 0,
        duration: 0.1
    }).then();
}

export const mobileCloseAnimation = (el) => {
    return gsap.to(el, {
        width: '5rem',
        borderRadius: '9999px',
        duration: 0.01
    }).then();
}

export const mobileMouseEnter = (el) => {
    return gsap.to(el, {
        height: '4rem',
        duration: 0.1
    });
}

export const mobileMouseLeave = (el) => {
    return gsap.to(el, {
        height: '3rem',
        duration: 0.1
    });
}

export const mouseEnter = (el) => {
    return gsap.fromTo(el, {
        opacity: 0,
        x: '3rem'
    }, {
        x: 0,
        autoAlpha: 1,
        duration: 0.5
    })
}

export const mouseLeave = (el) => {
    return gsap.to(el, {
        x: '100%',
        opacity: 0,
        duration: 0.5
    }).then();
}
