export const
    setKeydownListeners = () => {
    const checkboxes = Array.from(document.getElementsByClassName('checkbox-label'));
    for (const checkbox of checkboxes) {
        checkbox.addEventListener('keydown', (event) => {
            if (event.key === ' ' || event.keyCode === 32) {
                // Prevent the default action to stop scrolling when space is pressed
                event.preventDefault();

                const input = checkbox.getElementsByClassName('checkbox-input')[0];
                input.checked = !input.checked;
            }
        });
    }
}
