const formInputs = Array.from(document.querySelectorAll("[data-product-form-input]"));
const resultInputs = Array.from(document.querySelectorAll("[data-result-input]"));

if (formInputs && resultInputs) {
    for (const formInput of formInputs) {
        formInput.addEventListener("change", (e) => {
            document.querySelectorAll(`[data-input-id="${formInput.id}"]`).forEach((el) => {
                el.checked = e.target.checked;
            })
            // Workaround for doubled checkboxes where only the first input field changes when the label is clicked
            document.querySelectorAll(`input[id="${formInput.id}"]`).forEach((el) => {
                el.checked = e.target.checked;
            })
        })
    }
    for (const resultInput of resultInputs) {
        resultInput.addEventListener("change", (e) => {
            document.querySelectorAll(`input[id="${resultInput.dataset.inputId}"]`).forEach((el) => {
                el.checked = e.target.checked;
            })
        })
    }
}