import { setKeydownListeners } from './filter-keydown-handler.js'
import { initFilterRangeInput } from './filter-range-input.js'
import { filterboxDropdown } from './filterbox.js'
import { inputChangeListener } from './input-change-listener.js'
import './history-replacer.js'
import './active-filter-bar.js'

//Checkbox a11y:
setKeydownListeners()

//Range input init:
initFilterRangeInput()

filterboxDropdown()

inputChangeListener()