export const manualBulletAction = (manualBullet, sliderBullets, sliderElements) => {
    //Remove active class from not manually set bullets
    let activeBulletId
    for (const sliderBullet of sliderBullets) {
        if (sliderBullet.classList.contains('glide__bullet--active-manual')) {
            sliderBullet.classList.add('glide__bullet--active')
            activeBulletId = sliderBullet.dataset.glideDir.substring(1)
        } else {
            sliderBullet.classList.remove('glide__bullet--active')
        }
    }
    manualBullet.classList.remove('glide__bullet--active-manual')

    //Manually setting the active slide
    let activeSlide
    for (const sliderElement of sliderElements) {
        if (sliderElement.classList.contains('element-' + activeBulletId)) {
            activeSlide = sliderElement
            activeSlide.classList.add('glide__slide--active')
        } else {
            sliderElement.classList.remove('glide__slide--active')
        }
    }
}

export const manualSlideAction = (manualSlide, sliderBullets, sliderElements) => {
    //Remove active class from not manually set slide
    let activeSlide
    for (const sliderElement of sliderElements) {
        if (sliderElement.classList.contains('glide__slide--active-manual')) {
            sliderElement.classList.add('glide__slide--active')
            activeSlide = sliderElement
        } else {
            sliderElement.classList.remove('glide__slide--active')
        }
    }
    manualSlide.classList.remove('glide__slide--active-manual')

    //Manually setting the active bullet
    let activeBullet
    for (const sliderBullet of sliderBullets) {
        if (
            sliderBullet.dataset.glideDir.substring(1) ===
            activeSlide.dataset.elementIndex
        ) {
            activeBullet = sliderBullet
            activeBullet.classList.add('glide__bullet--active')
        } else {
            sliderBullet.classList.remove('glide__bullet--active')
        }
    }
}
