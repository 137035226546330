export const filterboxDropdown = () => {
    const boxes = Array.from(document.querySelectorAll('[data-finder-box]'));

    for (const box of boxes) {
        const title = box.querySelector('[data-finder-title]')
        const inputs = box.querySelector('[data-finder-inputs]')

        const chevronUp = title.querySelector('[data-chevron-up]');
        const chevronDown = title.querySelector('[data-chevron-down]');

        title.addEventListener('click', () => {
            if (title.classList.contains('chevron-up')) {
                title.classList.remove('chevron-up')
                title.classList.add('chevron-down')

                chevronUp.classList.add('hidden')
                chevronDown.classList.remove('hidden')
            } else {
                title.classList.remove('chevron-down')
                title.classList.add('chevron-up')

                chevronUp.classList.remove('hidden')
                chevronDown.classList.add('hidden')
            }
            inputs.classList.toggle('pt-6')
            inputs.classList.toggle('opacity-0')
            inputs.classList.toggle('h-0')
            inputs.classList.toggle('overflow-hidden')
        })
    }
}