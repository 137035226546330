import { manualBulletAction, manualSlideAction } from "./large-slider-actions.js";
import { imgAnimation } from './large-slider-animation.js';

export const setEventHandlers = (glide, slider) => {
//Event handler for manually set the active slide
    glide.on(['mount.after', 'run.after'], () => {
        const sliderBullets = Array.from(slider.getElementsByClassName('glide__bullet'))
        const sliderElements = Array.from(slider.getElementsByClassName('glide__slide'))
        const manualBullet = slider.getElementsByClassName('glide__bullet--active-manual')[0]
        const manualSlide = slider.getElementsByClassName('glide__slide--active-manual')[0]

        const prevImg = document.getElementsByClassName('prevImg')[0]

        if (prevImg) {
            const nextImgIndex = slider.getElementsByClassName('glide__slide--active')[0].dataset.elementIndex
            const nextImg = document.getElementsByClassName('image-' + nextImgIndex)[0]
            prevImg.classList.remove('prevImg')
            imgAnimation(prevImg, nextImg)
        } else if (manualSlide) {
            //Clicked slide action
            manualSlideAction(manualSlide, sliderBullets, sliderElements)
        } else if (manualBullet) {
            //Clicked bullet action
            manualBulletAction(manualBullet, sliderBullets, sliderElements)
        }
    })

    glide.on('run', () => {
        const prevImg = document.getElementsByClassName('prevImg')[0]

        if (prevImg) {
            return
        }

        const manualBullets = slider.getElementsByClassName('glide__bullet--active-manual')
        const manualSlides = slider.getElementsByClassName('glide__slide--active-manual')
        const manualBullet = manualBullets[0]
        const manualSlide = manualSlides[0]

        const activeBullet = slider.getElementsByClassName('glide__bullet--active')[0]
        const activeSlide = slider.getElementsByClassName('glide__slide--active')[0]

        if (manualBullets.length > 1 || manualSlides.length > 1) {
            return
        }

        if (manualBullet || manualSlide) {
            activeBullet.classList.remove('glide__bullet--active')
            activeSlide.classList.remove('glide__slide--active')
        }

        if (manualBullet) {
            //Clicked bullet action
            manualBullet.classList.add('glide__bullet--active')
            const bulletId = manualBullet.dataset.glideDir.substring(1)
            const slideToActivate = slider.getElementsByClassName('element-' + bulletId)[0]
            slideToActivate.classList.add('glide__slide--active')

            const activeSlideIndex = activeSlide.dataset.elementIndex
            const prevImg = document.getElementsByClassName('image-' + activeSlideIndex)[0]
            const slideToActivateIndex = slideToActivate.dataset.elementIndex
            const nextImg = document.getElementsByClassName('image-' + slideToActivateIndex)[0]

            imgAnimation(prevImg, nextImg)
        } else if (manualSlide) {
            //Clicked slide action
            manualSlide.classList.add('glide__slide--active')
            const slideId = manualSlide.dataset.elementIndex
            const bulletToActivate = slider.getElementsByClassName('bullet-' + slideId)[0]
            bulletToActivate.classList.add('glide__bullet--active')

            const activeSlideIndex = activeSlide.dataset.elementIndex
            const prevImg = document.getElementsByClassName('image-' + activeSlideIndex)[0]
            const bulletToActivateIndex = bulletToActivate.dataset.glideDir.substring(1)
            const nextImg = document.getElementsByClassName('image-' + bulletToActivateIndex)[0]

            imgAnimation(prevImg, nextImg)
        } else {
            const activeSlideIndex = activeSlide.dataset.elementIndex
            document
                .getElementsByClassName('image-' + activeSlideIndex)[0]
                .classList.add('prevImg')
        }
    })
}
