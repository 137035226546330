export const setClickHandlers = (slider, glide) => {
    //Manually setting the bullet click handlers
    const bullets = Array.from(slider.querySelector('[data-glide-el="controls[nav]"]').children)
    for (const bullet of bullets) {
        const prevImg = document.getElementsByClassName('prevImg')[0];
        if (prevImg) {
            return
        }
        bullet.addEventListener('click', () => {
            const manualBullet = slider.getElementsByClassName(
                'glide__bullet--active-manual'
            )[0]
            if (manualBullet) {
                return
            }
            const activeBullet = slider.getElementsByClassName('glide__bullet--active')[0]
            activeBullet.classList.remove('glide__bullet--active-manual')
            bullet.classList.add('glide__bullet--active-manual')
        })
    }

    //Manually setting the slider element click handlers
    const slideElements = Array.from(slider.querySelector('[data-slider-slides]').children)
    for (const slideElement of slideElements) {
        slideElement.addEventListener('click', () => {
            const prevImg = document.getElementsByClassName('prevImg')[0];
            if (prevImg) {
                return
            }
            const manualSlide = slider.getElementsByClassName('glide__slide--active-manual')[0]
            if (manualSlide) {
                return
            }
            const activeSlide = slider.getElementsByClassName('glide__slide--active')[0]
            if (activeSlide === slideElement) {
                return
            }
            activeSlide.classList.remove('glide__slide--active-manual')
            slideElement.classList.add('glide__slide--active-manual')

            //Manually changing the active slide
            glide.go('=' + slideElement.dataset.elementIndex)
        })
    }
}
