import Glide from '@glidejs/glide'
import Plyr from 'plyr'

const headerSlider = document.querySelector('[data-glide=header-slider]')

if (headerSlider) {
    new Glide(headerSlider, {
        type: 'slider',
        autoplay: 5500,
        animationDuration: 1500,
        hoverpause: true,
        perView: 1,
        gap: 0,
        dragThreshold: false, // Minimal mouse drag distance needed to change the slide. Use false for turning off a dragging.
        swipeThreshold: false // Minimal swipe distance needed to change the slide. Use false for turning off a swiping.
    }).mount()
}

const players = document.querySelectorAll('[data-glide=header-slider] video')

for (const player of players) {
    const plyr = new Plyr(players, {
        controls: null,
        autoplay: true,
        clickToPlay: false,
        disableContextMenu: true,
        muted: true,
        autopause: false,
        fullscreen: {
            enabled: false,
        },
    })

    plyr.on('pause', (event) => {
        event.detail.plyr.play()
    })

    plyr.play()
}
