import Glide from '@glidejs/glide'

const headerSlider = document.querySelector('[data-glide=machining_center]')

if (headerSlider) {
    new Glide(headerSlider, {
        type: 'carousel',
        autoplay: 5500,
        animationDuration: 1500,
        hoverpause: true,
        perView: 1,
        dragThreshold: false, // Minimal mouse drag distance needed to change the slide. Use false for turning off a dragging.
        swipeThreshold: false // Minimal swipe distance needed to change the slide. Use false for turning off a swiping.
    }).mount()
}
