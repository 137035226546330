import {
    closeAnimation,
    mobileCloseAnimation,
    mobileMouseEnter, mobileMouseLeave,
    mobileOpenAnimation, mouseEnter, mouseLeave,
    openAnimation
} from "./sidebar-animations.js";

export const setEventHandlers = () => {
    const sidebar = document.querySelector('[data-sidebar]');

    if (!sidebar) {
        return;
    }

    const sidebarElements = Array.from(sidebar.children);

    const sidebarElementsWithoutNavButton = [...sidebarElements].slice(0, -1);

    const sidebarElementsWithoutNavButtonReversed = [...sidebarElementsWithoutNavButton].reverse();

    const navButton = sidebarElements.at(-1);

    const sidebarToggle = sidebar.querySelector('[data-sidebar-toggle]');
    const iconWrapper = sidebarToggle.querySelector('[data-sidebar-icons]');
    const pillIcon = iconWrapper.querySelector('[data-sidebar-pill]');
    const xIcon = iconWrapper.querySelector('[data-sidebar-x]');

    // Fire closeAnimation once on load
    (async () => {
        console.log('load');
        for (const element of sidebarElementsWithoutNavButton) {
            await closeAnimation(element);
            element.classList.add('hidden')
        }
    })();

    //Click handler
    navButton.addEventListener('click', async () => {

        if (navButton.classList.contains('animation-active')) {
            return;
        }

        navButton.classList.add('animation-active');

        if (navButton.classList.contains('closed')) {
            navButton.classList.remove('closed');
            navButton.classList.add('open');

            sidebarToggle.classList.remove('md:w-20');
            sidebarToggle.classList.add('md:w-12');

            iconWrapper.classList.remove('w-20');
            iconWrapper.classList.add('w-12');

            pillIcon.classList.add('hidden');
            xIcon.classList.remove('hidden');


            for (const element of sidebarElementsWithoutNavButtonReversed) {
                element.classList.remove('hidden')
                await openAnimation(element);
            }

        } else {
            navButton.classList.remove('open');
            navButton.classList.add('closed');

            for (const element of sidebarElementsWithoutNavButton) {
                await closeAnimation(element);
                element.classList.add('hidden')
            }

            sidebarToggle.classList.add('md:w-20');
            sidebarToggle.classList.remove('md:w-12');

            iconWrapper.classList.remove('w-12');
            iconWrapper.classList.add('w-20');

            pillIcon.classList.remove('hidden');
            xIcon.classList.add('hidden');
        }
        navButton.classList.remove('animation-active');
    });

    //Hover handler
    for (const element of sidebarElementsWithoutNavButton) {
        element.addEventListener('mouseenter', () => {
            element.querySelector('[data-sidebar-text]').classList.add('md:inline-block');
            mouseEnter(element.querySelector('[data-sidebar-text]'))
        });
        element.addEventListener('mouseleave', async () => {
            await mouseLeave(element.querySelector('[data-sidebar-text]'))
            element.querySelector('[data-sidebar-text]').classList.remove('md:inline-block');
        });
    }
}
